//  -------------------------------------------------------- //
//  Roy Thomson Hall Variables
//  -------------------------------------------------------- //

$theme: 'roythomsonhall';

//  -------------------------------------------------------- //
//  Colours
//  -------------------------------------------------------- //

$primaryColor: #9c8b5a;

//  -------------------------------------------------------- //
//  Font Stacks
//  -------------------------------------------------------- //

$font-stacks: (
    display: '"Domaine", Georgia, serif',
    body: '"kepler-std", Georgia, serif',
    caption: 'Arial, sans-serif',
    serif: 'Georgia, serif',
    sans: 'Arial, sans-serif',
    trim: '"Trim Poster", Impact, sans-serif',
    domaine: '"Domaine", Georgia, serif',
    monospace: '"Hack", "Droid Mono", monospace'
);

// Keys should match those in $font-stacks
$font-scales: (
    display: (
        1: (font-size: 14px, line-height: 1.1, font-weight: bold),
        2: (font-size: 16px, line-height: 1.1, font-weight: bold),
        3: (font-size: 19px, line-height: 1.1, font-weight: bold),
        4: (font-size: 21px, line-height: 1.1, font-weight: bold),
        5: (font-size: 23px, line-height: 1.1, font-weight: bold),
        6: (font-size: 26px, line-height: 1.1, font-weight: bold),
        7: (font-size: 32px, line-height: 1, font-weight: bold),
        8: (font-size: 40px, line-height: 1.1, font-weight: bold),
        9: (font-size: 46px, line-height: 1.1, font-weight: bold)
    ),
    body: (
        1: (font-size: 14px, line-height: 1.5, font-weight: normal),
        2: (font-size: 16px, line-height: 1.5, font-weight: normal),
        3: (font-size: 18px, line-height: 1.5, font-weight: normal)
    ),
    caption: (
        1: (font-size: 12px, line-height: 1.4, font-weight: normal),
        2: (font-size: 14px, line-height: 1.4, font-weight: normal),
        3: (font-size: 16px, line-height: 1.4, font-weight: normal)
    )
);
